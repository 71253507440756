<!-- 添加跟进弹窗 -->
<template>
  <el-dialog
      width="50%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'添加跟进':'添加跟进'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="100px">
      <!-- 选项 -->
      <el-row>
        <div class="xuanxianglist">
          <div class="xuanxianglistactive" v-for="(item,index) in list" :key="index"  :class="{xuanxianglistactive1:index==isShow}" @click="activeItem(item,index)">
            <div style="display: flex;align-items: center;justify-content: center;">
              <img :src="item.img" style="width: 16px;height: 16px;margin-right: 10px;">
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>
      </el-row>

      <el-row style="margin-top: 20px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 16px;background: #a3a4b2;display: inline-block;"></span>
          <span style="font-size: 16px;font-weight: 500;color: #3f4155;margin-left: 10px;">下次提醒时间：</span>
          <el-input placeholder="请输入" v-model="form.input" style='width: 166px !important;'></el-input>
          <span style="margin-left: 10px;">分钟后</span>
        </div>
      </el-row>

      <el-row style="margin-top: 20px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 16px;background: #a3a4b2;display: inline-block;"></span>
          <span style="font-size: 16px;font-weight: 500;color: #3f4155;margin-left: 10px;">跟进备注</span>
        </div>
        <div style="margin-top: 10px;">
          <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入收回原因"
              v-model="form.textarea">
          </el-input>
        </div>
      </el-row>

      <el-row style="margin-top: 20px;margin-bottom: 20px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 16px;background: #a3a4b2;display: inline-block;"></span>
          <span style="font-size: 16px;font-weight: 500;color: #3f4155;margin-left: 10px;">电话拨打记录</span>
        </div>
        <div style="margin-top: 10px;display: flex;align-items: center;">
          <div style="font-size: 16px;font-weight: 400;color: #050505;">吴帅丽与救援用户通话</div>
          <audio
              style="margin-left: 20px;"
              :src="src"
              autoplay="autoplay"
              controls="controls"
              ref="audio"
          >Your browser does not support the audio element.</audio>
        </div>
      </el-row>


    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //司机相关
      list:[
        {
          img: require('../../../../assets/images/home/genjindianhuaactive.png'),
          name:'拨打车主电话'
        },
        {
          img: require('../../../../assets/images/home/genjindianhua.png'),
          name:'拨打订单来源客户电话'
        },
        {
          img: require('../../../../assets/images/home/genjindianhua.png'),
          name:'拨打司机电话'
        },
        {
          img: require('../../../../assets/images/home/genjindianhua.png'),
          name:'拨打服务商电话'
        }
      ],
      isShow:0,

      src:'',
    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择类型
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      if(item.name == '车主电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhua.png');
      }else if(item.name == '拨打订单来源客户电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhua.png');
      }else if(item.name == '拨打司机电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhua.png');
      }else if(item.name == '拨打服务商电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhuaactive.png');
      }

    },


  }
}
</script>

<style scoped lang="scss">
.xuanxianglist{

  .xuanxianglistactive{
    width: 23%;
    cursor: pointer;
    background: #e8e8e8;
    border: 1px solid #dadada;
    border-radius: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #7b7b7b;
    text-align: center;
    margin-top: 0;
    margin-right: 10px;
    display: inline-block;
  }
  .xuanxianglistactive1{
    background: #FF9B05;
    color: #FFFFFF;
    border-color: #FF9B05;
  }

  //最后一个
  .xuanxianglistactive:last-child{
    margin-right: 0;
  }

}
</style>
